<template>
  <v-container id="regular-tables" fluid tag="section">
    <base-material-card
      icon="mdi-help-circle"
      title="consultations List"
      class="px-5 py-3">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="params.phone"
            label="Search by phone number"
            single-line
            v-on:keyup.enter="getConsultations(1)"
          ></v-text-field>
          <v-text-field
            v-model="params.consultation_id"
            label="Search by consultation_id"
            single-line
            v-on:keyup.enter="getConsultations(1)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="float-right text-right align-end">
          <v-btn class="primary align-end" :href="downloadable_file" download v-if="export_file_status === 'completed'">
            <span class="mb-3">
               <v-icon left>
                mdi-download
               </v-icon>
              Download
            </span>
          </v-btn>
          <div class="float-right text-center align-end">
            <v-dialog
              v-model="download_report_dialog"
              width="600"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="info" v-bind="attrs" v-on="on" :disabled="export_file_status === 'in_queue'">
                  <v-icon left>
                    mdi-folder-plus-outline
                  </v-icon>
                  <template v-if="export_file_status === 'completed' || !export_file_status">
                    request a download file
                  </template>
                  <template v-if="export_file_status === 'in_queue'">
                    download file requested, and will be available soon
                  </template>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Request Download File</span>
                </v-card-title>
                <v-card-text>
                  <VValidationErrors :validationErrors="validationErrors"></VValidationErrors>
                </v-card-text>
                <v-card-text>
                  <v-container>
                    <v-form ref="downloadReportForm">
                      <v-row>
                        <v-col cols="6" sm="6">
                          <v-menu
                            v-model="menu_date_from"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="reportData.date_from"
                                label="Select date from"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :max="new Date().toISOString().substr(0, 10)"
                              v-model="reportData.date_from"
                              @input="menu_date_from = false">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" sm="6">
                          <v-menu
                            v-model="menu_date_to"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="reportData.date_to"
                                label="Select date to"
                                append-icon="mdi-calendar-range"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :max="new Date().toISOString().substr(0, 10)"
                              v-model="reportData.date_to"
                              @input="menu_date_to = false">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="generateReport"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="blue"
      ></v-progress-linear>
      <v-simple-table>
        <thead>
        <tr>
          <th class="primary--text">
            Consultation ID
          </th>
          <th v-if="$auth.getPartnerName() === 'tawuniya'" class="primary--text">
            Tawuniya User ID
          </th>
          <th class="primary--text">
            Consultation Type
          </th>
          <th class="primary--text">
            First Name
          </th>
          <th class="primary--text">
            Phone Number
          </th>
          <th class="primary--text">
            Gender
          </th>
          <th class="primary--text">
            Date of Birth
          </th>
          <th class="primary--text">
            Doctor
          </th>
          <th class="primary--text">
            Status
          </th>
          <th class="primary--text">
            Call Duration
          </th>
          <th class="primary--text">
            Created At
          </th>
          <th class="text-center primary--text">
            Options
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="consultation in consultations">
          <td>
            {{ consultation.consultation_id }}
          </td>
          <td v-if="$auth.getPartnerName() === 'tawuniya'">
            {{ consultation.twUserRefill ? consultation.twUserRefill.tw_user_id : '--' }}
          </td>
          <td>
            {{ consultation.tbi_consultation_category_id | consultationType}}
          </td>
          <td>
            <template v-if="consultation.phr && consultation.phr.name">
              {{ consultation.phr.name }}
            </template>
            <template v-else>
              {{ consultation.member.first_name  + ' ' + consultation.member.last_name}}
            </template>
          </td>
          <td>
            <template v-if="consultation.phr && consultation.phr.phone">
              {{ consultation.phr.phone }}
            </template>
            <template v-else>
              {{ consultation.member ? consultation.member.phone : '' }}
            </template>
          </td>
          <td>
            <template v-if="consultation.phr && consultation.phr.gender">
              {{ consultation.phr.gender }}
            </template>
            <template v-else>
              {{ consultation.member ? consultation.member.gender : '' }}
            </template>
          </td>
          <td>
            <template v-if="consultation.phr && consultation.phr.date_of_birth">
              {{ consultation.phr.date_of_birth }}
            </template>
            <template v-else>
              {{ consultation.member ? consultation.member.date_of_birth : '' }}
            </template>
          </td>
          <td>
            {{ consultation.location ? consultation.location.name_en ? consultation.location.name_en : consultation.location.name_ar : '' }}
          </td>
          <td>
            {{ consultation.status }}
          </td>
          <td>
            {{ consultation.callDuration ? consultation.callDuration + ' sec' : ''}}
          </td>
          <td>
            {{ consultation.date_added }}
          </td>
          <td class="text-center">
            <div class="d-inline-flex">
              <v-btn x-small fab color="info" class="mr-2" :to="{ path: '/consultations/'+ consultation.consultation_id}">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <div class="text-center">
      <v-pagination
        v-model="params.page"
        :length="pagination.length"
        :total-visible="7"
        @input="getConsultations"
        @next="getConsultations"
        @previous="getConsultations"
      />
    </div>
    <div class="py-3"/>
    <v-btn x-large fab dark fixed right bottom color="blue"  to="/consultations/create" v-if="$auth.getEnabledFeatures().includes('create_consultation')">
      <v-icon large>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
  import { VueTelInput } from 'vue-tel-input'
  import VValidationErrors from "../../components/base/VValidationErrors";

  export default {
    name: 'ConsultationList',
    components: {
      VValidationErrors,
      VueTelInput,
    },
    created () {
      this.getConsultations()
    },
    data () {
      return {
        download_report_dialog: false,
        export_file_status: null,
        downloadable_file: null,
        menu_date_from: false,
        reportData: {
          date_from: '',
          date_to: '',
        },
        menu_date_to: false,
        loading: false,
        countryCode: '',
        mobile: '',
        params: {
          phone: '',
          page: 1,
        },
        pagination: {
          length: 0,
          total: 0
        },
        consultations: [],
        validationErrors: {},
      }
    },
    methods: {
      countrySelected (val) {
        this.countryCode = val.dialCode
      },
      getConsultations (page = false) {
        if (page) {
          this.params.page = page;
        }
        this.loading = true;
        this.$authApi.get('consultations', {
          params: this.params
        }).then(res => {
          this.loading = false;
          this.consultations = res.data.data.consultations.items
          this.pagination.length = res.data.data.consultations.extra._meta.pageCount;
          this.export_file_status = res.data.data.export_file_status;
          this.downloadable_file = res.data.data.downloadable_file;
          console.log(this.consultations)
        }).catch(err => {
          this.loading = false;
        })
      },
      generateReport() {
        this.$authApi.post('consultations/generate-report', this.reportData).then(res => {
          this.download_report_dialog = false;
          this.export_file_status = 'in_queue';
          this.validationErrors = {};
        }).catch(error => {
          if (error.response) {
            this.validationErrors = error.response.data.errors;
          }
        })
      }
    },
  }
</script>

